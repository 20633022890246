<template>
  <div class="home-mobile">
    <top-header
      :class="{'ct-h-t': true, 'scrolled': scrollTop > 0}"
      :navs="navs"
      ref="home_top_bar">
    </top-header>
    <div class="block block-1">
      <div class="st-wh">
        <h1>OrionBase</h1>
        <h3>专业的机器人开放平台，快速落地机器人业务</h3>
        <div class="primary-btn xl" @click="toOB">
          <span>立即使用</span>
        </div>
        <div ref="m_animation_wrap_1" class="block-1-bg">
          <img src="@/assets/images/block-1-bg.png" alt="">
          <div class="rects-wrap">
            <div class="rect-1-1"></div>
            <div class="rect-1-2"></div>
          </div>
          <svg class="svg-1-1" width="56" height="36" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <polyline
              class="line-track line-track-1"
              points="1,36 1,1 56,1"></polyline>
          </svg>
          <img class="img-1-1" src="@/assets/images/block-1-1.png" alt="">
          <svg class="svg-1-2" width="38" height="2" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <polyline
              class="line-track line-track-2"
              points="0,1 38,1"></polyline>
          </svg>
          <div class="light-img-wrap">
            <img class="img-1-2" src="@/assets/images/block-1-2.png" alt="">
            <div class="img-1-2-light"></div>
          </div>
          <svg class="svg-1-3" width="36" height="71" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <polyline
              class="line-track line-track-3"
              points="1,1 24,1 24,70 36,70"></polyline>
          </svg>
          <img class="img-1-3" src="@/assets/images/block-1-3.png" alt="">
        </div>
      </div>
    </div>
    <div class="block block-2">
      <div class="st-wh">
        <div class="advan-item">
          <img src="@/assets/images/advan-write_2x.png" alt="">
          <div>
            <h5>机器人业务解决方案</h5>
            <p>提供了涵盖机器人应用开发、运营配置、运维监控的完整解决方案，一站式解决机器人业务落地全部问题</p>
          </div>
        </div>
        <div class="advan-item">
          <img src="@/assets/images/advan-data_2x.png" alt="">
          <div>
            <h5>简单开发，快速落地</h5>
            <p>无成本接入、低成本开发、机器人真实还原模拟，从0到1完成机器人业务开发落地只需要3天时间</p>
          </div>
        </div>
        <div class="advan-item">
          <img src="@/assets/images/advan-team_2x.png" alt="">
          <div>
            <h5>专业的服务团队支持</h5>
            <p>专业的售后运维团队全程跟进，快速响应问题，为机器人业务落地带来全生命周期的支持与帮助</p>
          </div>
        </div>
      </div>
    </div>
    <div class="block block-3">
      <div class="st-wh">
        <h1 class="pri-title">产品功能</h1>
        <div class="power-wrap">
          <superslide
            class="power-slide-wrap"
            :options="pointSlideOptions"
            >
            <div class="power-slide-body"
              ref="m_power_slide_bd"
              @touchstart="(e) => touchStart(e, 'power')"
              @touchmove="(e) => touchMove(e, 'power')"
              @touchend="(e) => touchEnd(e, 'power')">
              <div
                v-for="(item, index) in power_list"
                :key="index"
                class="power-item"
              >
                <div class="power-links">
                  <a href="javascript:;" @click="toOB">立即使用>></a>
                  <a :href="item.doc_url" @click.prevent="toDoc">查看文档>></a>
                </div>
                <div :class="`pic-bg anim-${index + 1}`" :ref="'m_power_slide_anim_' + index">
                  <img :class="`anim-bg anim-${index + 1}-bg`" :src="item.bg_url">
                  <img v-for="(src, i) in item.imgs" :class="`m-power-anim-img img-${i + 1}`" :key="i" :src="src">
                </div>
                <h2>{{item.title}}</h2>
                <div
                  class="point-p"
                  v-for="(p, i) in item.intros"
                  :key="i"
                  >{{p}}</div>
              </div>
            </div>
            <div 
              class="power-slide-bar"
              slot="titCell">
              <span
                v-for="(item, index) in power_list"
                :key="index">
              </span>
            </div>
            <div ref="power_prev_btn" class="power-prev-btn"></div>
            <div ref="power_next_btn" class="power-next-btn"></div>
          </superslide>
        </div>
      </div>
    </div>
    <div class="block block-4">
      <div class="st-wh">
        <h1 class="pri-title">应用场景</h1>
        <div class="case-wrap">
          <superslide
            :options="caseSlideOptions"
            >
            <div
              ref="case_slide_bar"
              class="case-slide-bar"
              slot="titCell">
              <ul>
                <li
                  v-for="(item, index) in case_list"
                  :ref="'case_bar_' + index"
                  :key="index">
                  {{item.label}}
                </li>
              </ul>
            </div>
            <div class="case-slide-body"
              @touchstart="(e) => touchStart(e, 'case')"
              @touchmove="(e) => touchMove(e, 'case')"
              @touchend="(e) => touchEnd(e, 'case')">
              <div
                class="case-item"
                v-for="(item, index) in case_list"
                :key="index">
                <img :src="item.pic" alt="">
                <div class="intro-label">{{item.label}}</div>
                <div class="intro" :title="item.intro">{{item.intro}}</div>
              </div>
            </div>
            <div ref="case_prev_btn" class="case-prev-btn"></div>
            <div ref="case_next_btn" class="case-next-btn"></div>
          </superslide>
        </div>
      </div>
    </div>
    <div class="block block-5">
      <div class="st-wh">
        <h1 class="pri-title">合作伙伴</h1>
        <div class="corps">
          <div class="corp-row">
            <div class="corp-item">
              <img src="@/assets/images/corp-wfrj_2x.jpg" class="ht-60" alt="">
            </div>
            <div class="corp-item">
              <img src="@/assets/images/corp-md_2x.jpg" class="ht-70" alt="">
            </div>
            <div class="corp-item">
              <img src="@/assets/images/corp-fsty-c_2x.jpg" class="ht-69" alt="">
            </div>
            <div class="corp-item">
              <img src="@/assets/images/corp-syzn_2x.jpg" class="ht-42" alt="">
            </div>
            <div class="corp-item">
              <img src="@/assets/images/corp-qd_2x.jpg" class="ht-69" alt="">
            </div>
            <div class="corp-item">
              <img src="@/assets/images/corp-zkhy_2x.jpg" class="ht-56" alt="">
            </div>
            <div class="corp-item">
              <img src="@/assets/images/corp-darke_2x.jpg" class="ht-56" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="block block-6">
      <div class="st-wh">
        <div class="below-entry">
          <h1>OrionBase开发者平台</h1>
          <div class="btn-wrap">
            <div class="primary-btn btn-mobile" @click="toOB">
              <span>立即使用</span>
            </div>
          </div>
          <div class="below-bg">
            <img src="@/assets/images/home-foot-bg-1_2x.png" alt="">
            <div class="line"></div>
          </div>
        </div>
        <div class="about-row">
          <img class="foot-logo" src="@/assets/images/ob-logo-foot.png" alt="">
          <div class="foot-btns">
            <div class="foot-navs">
              <a href="/doc" @click.prevent="toDoc">文档中心</a>
              <a href="/doc" @click.prevent="toDoc">关于我们</a>
              <a href="mailto:OrionBase@ainirobot.com"
                @click="$utils.GATracking('点击意见反馈','点击','首页')">意见反馈</a>
              <a href="/doc" @click.prevent="toDoc">用户协议</a>
              <a href="/doc" @click.prevent="toDoc">隐私政策</a>
            </div>
            <div class="mail">
              <svg class="email-svg" width="15px" height="13px" version="1.1" xmlns="http://www.w3.org/2000/svg"
                style="background: rgba(27, 26, 58, 0.8); border-radius: 2px; vertical-align: middle; margin-right: 5px;"
              ><path fill="none" stroke="rgba(255, 255, 255, 1)" stroke-width="1.4" d="M0 3.5 L7.5 7.2 L15 3.5"></path></svg>
              <a href="mailto:OrionBase@ainirobot.com"
                @click="$utils.GATracking('点击邮箱链接','点击','首页')">邮箱：OrionBase@ainirobot.com</a>
            </div>
          </div>
        </div>
        <div class="cn-bei">
          <a 
            target='_blank'
            href='https://beian.miit.gov.cn/'
            @click="$utils.GATracking('备案号','点击','首页底导-ICP备案号')"
          >猎户星空©2019  • 京公网安备 11010502039185号，京ICP备17016764号-2</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import topHeader from '@/components/common/top-header/mobile-top-header'
// import cnSlider from '@/components/common/slider'
const NAVS = [
  {
    title: '文档中心',
    route_name: 'doc',
    path: '/doc'
  }
]
const POWER_LIST = [
  {
    title: '应用开发',
    doc_url: '/doc',
    bg_url: require('@/assets/images/power-dev-bg-1.png'),
    imgs: [
      require('@/assets/images/power-dev-1_2x.png'),
      require('@/assets/images/power-dev-4_2x.png'),
      require('@/assets/images/power-dev-3_2x.png')
    ],
    intros: [
      '提供免费、标准化的线上接入流程，大量实际业务代码开源',
      '拥有业界领先的自然语言处理能力，提供专业、灵活的对话管理技术，为开发者提供全方位的NLP定制服务'
    ]
  },
  {
    title: '实操模拟',
    doc_url: '/doc',
    bg_url: require('@/assets/images/power-act-bg-1.png'),
    imgs: [
      require('@/assets/images/power-act-msg_2x.png'),
      require('@/assets/images/power-act-say_2x.png'),
      require('@/assets/images/power-act-dash-curve.png'),
      require('@/assets/images/power-act-1_2x.png'),
    ],
    intros: [
      '真实模拟机器人的开发、运行环境，适配多种形态的机器人，解决业务初期无真机的烦恼',
      '机器人角色灵活切换，完美打通语音链路、运营系统等能力模块，轻松应对复杂的业务场景'
    ]
  },
  {
    title: '运营监控',
    doc_url: '/doc',
    bg_url: require('@/assets/images/power-op-bg-1.png'),
    imgs: [
      require('@/assets/images/power-op-apk_2x.png'),
      require('@/assets/images/power-op-apk-gray_2x.png'),
      require('@/assets/images/power-op-dash-curve.png'),
      require('@/assets/images/power-op-refresh_2x.png'),
      require('@/assets/images/power-op-curve2.png'),
    ],
    intros: [
      '应用简单发布、随时升级，快速迭代您的机器人业务',
      '24小时实时异常监控，及时预警通知，全方位保障您机器人业务的稳定性'
    ]
  }
]
const CASE_LIST = [
  {
    label: '医疗',
    intro: '为患者提供无接触红外测温、导诊、预诊、位置指引、医疗咨询等服务，协助医院进行患者分流，提升安全性、降低人力成本，同时能有效辅助医生诊断决策，提升诊疗能力',
    pic: require("@/assets/images/case-medical.png")
  },
  {
    label: '新零售',
    intro: '为顾客提供商品导购、查询、商品推荐、商品介绍、位置指引等服务，为商家提供店铺招揽、店铺引流、营销互动等服务，沉淀顾客消费大数据，挖掘潜在消费能力，促进营销智慧化，打造零售新业态',
    pic: require("@/assets/images/case-market.png")
  },
  {
    label: '政务',
    intro: '为群众提供便捷取号、政务咨询、政务办理等服务，有效帮助政务大厅分流，为工作人员提供桌面智能助理服务，根据用户问题快速给出答案或推荐话术，辅助工作效率提升',
    pic: require("@/assets/images/case-gover_affairs.png")
  },
  {
    label: '企业服务',
    intro: '为企业提供前台广告播放、主动问候、来访人员登记、无关人员安检排查、无接触红外测温、企业引领参观等服务，保证企业接待水平的智能化、高标准、统一性，有效降低人力成本，同时便于对来访人员进行大数据分析',
    pic: require("@/assets/images/case-corp_service.png")
  },
  {
    label: '展馆',
    intro: '为博物馆、展览馆等提供高水平、标准统一的讲解服务，快速适应不同讲解场景、复杂度的内容，降低人工讲解的成本、减少讲解失误率，同时结合实际场景完成智能引导，快捷解决游客需求，有效提升游客的参观满意度',
    pic: require("@/assets/images/case-exhibition.png")
  },
  {
    label: '教育',
    intro: '为校园提供迎宾、访客接待、校园导览、咨询讲解等智能服务，针对图书馆、实验室等场景，提供智慧索引、知识科普、咨询问答等一站式服务，有效缓解教职工工作压力、提升校园智能化水平，同时方便学校实现统一的信息化管理',
    pic: require("@/assets/images/case-education.png")
  },
  {
    label: '酒店',
    intro: '为酒店提供24H的前台值班、入住办理、咨询问答等服务，可根据不同入住时段设置接待欢迎语，主动打招呼互动提供引领等服务，还可以设置酒店常用地点库，介绍酒店宴会场地、包餐菜单等内容，获取周边美食、景点、交通情况等，提供最优质的入住体验',
    pic: require("@/assets/images/case-hotel.png")
  },
  {
    label: '银行',
    intro: '为客户提供主动招揽、基础操作问题答疑、基础业务办理等服务，降低人工成本，同时针对VIP用户精准营销，提供定制化、高标准服务，另外可针对等候区域用户可提供游戏互动式活动推广、有效增加业务转化量',
    pic: require("@/assets/images/case-bank.png")
  }
]
export default {
  components: {
    topHeader,
    // cnSlider
  },
  data() {
    let that = this
    return {
      navs: Object.freeze(NAVS),
      scrollTop: 0,
      power_list: Object.freeze(POWER_LIST),
      pointSlideOptions: {
        mainCell: '.power-slide-body',
        titCell: '.power-slide-bar span',
        effect: 'leftLoop',
        trigger: 'click',
        interTime: 5000,
        autoPlay: true,
        easing: 'easeOutQuint',
        prevCell: '.power-prev-btn',
        nextCell: '.power-next-btn',
        startFun: (index, total) => {
          let curr_ref = this.$refs['m_power_slide_anim_' + index]
          if (!curr_ref) return
          let curr_item = curr_ref[0]
          if (!curr_item) return
          let wh = window.innerHeight
          if (curr_item.className.indexOf('animate') == -1) {
            let bound = curr_item.getBoundingClientRect()
            if (bound.top > 50 && bound.top < wh - bound.height) {
              curr_item.classList.add('animate')
            }
          }
        }
      },
      case_list: Object.freeze(CASE_LIST),
      caseSlideOptions: {
        mainCell: '.case-slide-body',
        titCell: '.case-slide-bar li',
        effect: 'leftLoop',
        trigger: 'click',
        interTime: 5000,
        autoPlay: true,
        easing: 'easeOutQuint',
        prevCell: '.case-prev-btn',
        nextCell: '.case-next-btn',
        startFun: (index, total) => {
          let winh = window.innerHeight
          let bar = this.$refs.case_slide_bar
          if (!bar) return;
          let bounds = bar.getBoundingClientRect()
          let top = bounds.top
          // 判断bar区域是否在可视区，在时 需要将当前高亮的tab自动滚动出来
          if (top > 32 && top < winh - 22) {
            let currTriggerEl = this.$refs['case_bar_' + index][0]
            currTriggerEl.scrollIntoView({
              block: 'nearest',
              // inline: '',
              behavior: 'smooth'
            });
          }
        }
      },
      curr_touch_case: {
        sx: 0,
        ex: 0,
        st: 0,
        et: 0
      },
      curr_touch_power: {
        sx: 0,
        ex: 0,
        st: 0,
        et: 0
      },
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    this.handleScroll()
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    touchStart(e, type) {
      let obj = this['curr_touch_' + type]
      obj.sx = e.touches[0].screenX
      obj.st = e.timeStamp
    },
    touchMove(e, type) {
      let obj = this['curr_touch_' + type]
      obj.ex = e.touches[0].screenX
      obj.et = e.timeStamp
    },
    touchEnd(e, type) {
      let obj = this['curr_touch_' + type]
      let { sx, ex, st, et } = obj
      let diffx = ex - sx
      let times = et - st
      if (times > 500) return
      if (diffx > 10) {
        this.$refs[type + '_prev_btn'].click()
      }
      if (diffx < -10) {
        this.$refs[type + '_next_btn'].click()
      }
      obj = {
        st: 0,
        et: 0,
        sx: 0,
        ex: 0
      }
    },
    handleScroll(e) {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      this.scrollTop = scrollTop;
      let wrap1 = this.$refs.m_animation_wrap_1
      if (wrap1) {
        if (wrap1.className.indexOf('animate') == -1) {
          let wh = window.innerHeight
          let bound = wrap1.getBoundingClientRect()
          if (bound.top > -40 && bound.top < wh) {
            wrap1.classList.add('animate')
          }
        }
      }
    },
    toOB() {
      location.href = this.$envConfig.obUrl
    },
    toDoc() {
      this.$router.push('/doc')
    }
  }
}
</script>
<style lang="less">
@import url("~style/style/btns.less");
@blue: #6975FF;
@bezier: cubic-bezier(.70, .65, .23, .1);
@lineColor: #1B1A3A;
@conWidth: 100%;
.home-mobile {
  .ct-h-t {
    &.scrolled {
      background: rgba(252, 252, 253, 1);
      box-shadow: 0px 1px 9px 0px rgba(41, 43, 57, 0.2);
    }
    .top-header {
      width: @conWidth;
      margin: 0 auto;
      padding: 0 40px;
    }
  }
  .block {
    padding: 40px 0;
    .pri-title {
      font-size: 22px;
      line-height: 1.4;
      color: #1B1939;
      text-align: center;
    }
    img {
      display: block;
      max-width: 100%;
      max-height: 100%;
    }
    a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .st-wh {
    width: @conWidth;
    height: 100%;;
    margin: 0 auto;
    box-sizing: border-box;
  }
  .block-1 {
    overflow: hidden;
    .st-wh {
      position: relative;
      height: 100%;
    }
    h1, h3 {
      text-align: center;
    }
    h1 {
      color: #1B1939;
      font-size: 16vw;
      line-height: 1.4;
      padding: 20vh 0 4vh;
    }
    h3 {
      color: #393843;
      font-size: 15px;
      line-height: 20px;
      font-weight: normal;
      padding: 0 30px;
    }
    .primary-btn {
      display: block;
      width: 220px;
      margin: 6vh auto 4vh;
    }
    .block-1-bg {
      position: relative;
      width: 100%;
      .rects-wrap, svg, .img-1-1, .img-1-3, .light-img-wrap, .img-1-2-light {
        position: absolute;
      }
      .img-1-1, .img-1-2, .img-1-3 {
        opacity: 0;
      }
      .rects-wrap {
        left: 36.35%;
        top: 51.4%;
        width: 9.5%;
        height: 2.4%;
        opacity: 0;
        overflow: hidden;
        div {
          position: absolute;
          box-sizing: border-box;
          top: 0;
          height: 100%;
          border: 1px solid @lineColor;
          border-radius: 1px;
          margin: 0;
        }
        .rect-1-1 {
          left: 0;
          width: 27%;
        }
        .rect-1-2 {
          right: 0;
          width: 66%;
        }
      }
      .line-track {
        fill: none;
        stroke: @lineColor;
        stroke-width: 1px;
      }
      .svg-1-1 {
        width: 6.35%;
        height: 4.08%;
        left: 43.08%;
        top: 35.8%;
      }
      .img-1-1 {
        width: 6.46%;
        left: 48.07%;
        top: 31%;
      }
      .svg-1-2 {
        width: 4.8%;
        left: 66.4%;
        top: 29%;
      }
      .light-img-wrap {
        width: 6.2%;
        height: 9.5%;
        left: 70.75%;
        top: 22%;
        overflow: hidden;
      }
      .img-1-2 {
        width: 100%;
      }
      .img-1-2-light {
        z-index: 10;
        left: 0;
        top: 0;
        width: 20px;
        height: 20px;
        opacity: 0;
        background: linear-gradient(135deg, rgba(255, 255, 255, 0) 40%, rgba(255, 255, 255, .85) 50%,rgba(255, 255, 255, 0) 60%);
      }
      .svg-1-3 {
        width: 4.08%;
        left: 76%;
        top: 29%;
        polyline {
          position: relative;
          stroke-width: 2px;
          transform: scale(0.45);
        }
      }
      .img-1-3 {
        width: 5.2%;
        left: 80%;
        top: 38%;
      }
    }
    .block-1-bg.animate {
      .rects-wrap {
        animation: fadeIn1 0.5s 1s both;
      }
      .line-track-1 {
        animation: track11 0.5s @bezier 1.5s both;
      }
      .img-1-1 {
        animation: fadeIn1 1s linear 2s both, shake1 .6s ease-in-out 3s 3 both;
      }
      .line-track-2 {
        animation: track12 0.3s @bezier 1.5s both;
      }
      .img-1-2 {
        animation: fadeIn1 1s linear 1.8s both;
      }
      .img-1-2-light {
        animation: lightCross1 1.5s ease-in-out 2.8s 1;
      }
      .line-track-3 {
        animation: track13 0.5s @bezier 3.5s both;
      }
      .img-1-3 {
        animation: fadeIn1 1s linear 4s both, breath1 1s ease-in-out 5s 2 both;
      }
    }
  }
  .block-2 {
    padding: 40px 0;
    background: rgba(236, 237, 254, 1);
    .advan-item {
      padding: 30px 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      div {
        flex: 1;
      }
      h5 {
        font-size: 15px;
        line-height: 1.4;
        margin: 0 0 12px;
      }
      p {
        font-size: 13px;
        line-height: 1.5;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        overflow: hidden;
        -webkit-box-orient: vertical;
      }
      img {
        display: block;
        width: 86px;
        height: 86px;
        margin-right: 30px;
      }
    }
  }
  .block-3 {
    background: #fff;
    padding-bottom: 0;
    h1 {
      margin: 0 0 10px;
    }
    .power-slide-wrap {
      position: relative;
      padding-bottom: 60px;
      .power-slide-bar {
        position: absolute;
        bottom: 30px;
        left: 0;
        right: 0;
        text-align: center;
        span {
          display: inline-block;
          width: 8px;
          height: 8px;
          border-radius: 4px;
          background: @blue;
          margin: 0 4px;
          &.on {
            width: 46px;
          }
        }
      }
    }
    .power-item {
      padding: 0 12vw;
      .pic-bg {
        height: 40vw;
        position: relative;
        img {
          max-height: unset;
        }
      }
      h2 {
        font-size: 15px;
        line-height: 1.4;
        margin: 40px 0 20px;
        color: #1B1939;
        font-weight: normal;
        text-align: center;
      }
      .point-p {
        font-size: 14px;
        line-height: 1.5;
        margin-bottom: 10px;
        color: #393741;
        font-weight: 300;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          width: 4px;
          height: 4px;
          border-radius: 50%;
          left: -10px;
          top: 8px;
          background: #393842;
        }
      }
      .power-links {
        text-align: center;
        margin-bottom: 60px;
        a {
          font-size: 14px;
          line-height: 1.4;
          color: @blue;
          margin-right: 27px;
          &:last-of-type {
            margin-right: 0;
          }
        }
      }
    }
    .anim-bg {
      position: relative;
      z-index: 10;
    }
    .m-power-anim-img {
      position: absolute;
      z-index: 10;
      opacity: 0;
    }
    .anim-1 {
      .m-power-anim-img {
        z-index: 9;
      }
      .img-1 {
        width: 15%;
        top: -4.5%;
        right: 15.5%;
      }
      .img-2 {
        width: 66%;
        top: -30%;
        left: 7%;
      }
      .img-3 {
        width: 24%;
        top: -16%;
        left: -3%;
      }
    }
    .anim-1.animate {
      .img-1 {
        animation: fadeIn1 .5s linear 0.5s both, shake1 .6s ease-in-out 1s 2 both;
      }
      .img-2 {
        animation: fadeIn1 .5s 1.6s both;
      }
      .img-3 {
        animation: fadeIn1 .5s 2.1s both, breath1 .6s ease-in-out 2.6s 2 both;
      }
    }

    .anim-2 {
      .img-1 {
        width: 16%;
        right: -2%;
        top: 22%;
      }
      .img-2 {
        width: 16%;
        right: 5%;
        top: 12.5%;
      }
      .img-3 {
        width: 11%;
        left: -4%;
        top: 46%;
      }
      .img-4 {
        width: 15%;
        left: -6%;
        top: 7%;
      }
    }
    .anim-2.animate {
      .img-1 {
        animation: rightIn .5s .5s both;
      }
      .img-2 {
        animation: leftIn .5s 1s both;
      }
      .img-3 {
        animation: fadeIn1 .5s 1.5s both;
      }
      .img-4 {
        animation: scaleIn .5s ease-in-out 2s both, shake1 .6s ease-in-out 2.5s 2 both;
      }
    }

    .anim-3 {
      .img-1 {
        width: 20%;
        left: 8%;
        top: 73%;
      }
      .img-2 {
        width: 20%;
        left: 17%;
        top: 73%;
      }
      .img-3 {
        width: 8%;
        left: 1%;
        top: 43%;
      }
      .img-4 {
        width: 8%;
        left: -3%;
        top: 60%;
      }
      .img-5 {
        width: 45%;
        right: 3%;
        top: 30%;
      }
    }
    .anim-3.animate {
      .img-1 {
        animation: scaleIn .4s ease-in .5s both;
      }
      .img-2 {
        animation: scaleIn .4s ease-in-out .5s both;
      }
      .img-3 {
        animation: fadeIn1 .5s 1s both;
      }
      .img-4 {
        animation: fadeIn1 .5s 1.5s both, rotateSelf .8s 2s 2 both;
      }
      .img-5 {
        animation: scaleIn 1s ease-in-out 3.6s both;
      }
    }
  }
  .block-4 {
    background: #F3F5F5;
    .case-wrap {
      margin-top: 20px;
      position: relative;
    }
    .case-slide-bar {
      height: 30px;
      margin: 0 24px 10px;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 2px;
        height: 2px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: transparent;
        border-radius: 2px;
        opacity: 0.5;
      }
      &:hover {
        &::-webkit-scrollbar-thumb {
          background-color: #EAEAEA;
        }
      }
      ul {
        height: 100%;
        white-space: nowrap;
        &::after {
          content: "";
          display: block;
          height: 0;
          overflow: hidden;
          clear: both;
        }
      }
      li {
        display: inline-block;
        list-style: none;
        height: 22px;
        line-height: 22px;
        position: relative;
        margin: 0 3vw;
        &:first-of-type {
          margin-left: 0;
        }
        &:last-of-type {
          margin-right: 0;
        }
        &::after {
          content: "";
          position: absolute;
          bottom: -2px;
          z-index: 10;
          left: 0;
          right: 0;
          height: 2px;
          background: @blue;
          transform: scaleX(0);
          opacity: 0;
          transition: opacity .15s, transform .2s cubic-bezier(.20, .60, .23, .86);
        }
        &.on {
          color: @blue;
          &::after {
            opacity: 1;
            transform: scaleX(1);
          }
        }
      }
    }
    .case-item {
      padding: 5vw;
      background: #fff;
      margin: 0 24px;
      img {
        margin: 0 auto;
      }
    }
    .intro-label {
      font-size: 15px;
      line-height: 1.2;
      margin: 20px 0 15px;
      color: #393842;
      text-align: center;
    }
    .intro {
      font-size: 14px;
      line-height: 1.4;
      color: #898989;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      overflow: hidden;
      -webkit-box-orient: vertical;
    }
  }
  .block-5 {
    .corps {
      margin: 20px 15px 30px;
    }
    .corp-row {
      .corp-item:last-of-type {
        margin-right: 0;
      }
    }
    .corp-item {
      display: inline-flex;
      width: 33.33333%;
      height: 12vw;
      padding: 2vw 3vw;
      vertical-align: middle;
      align-items: center;
      box-sizing: border-box;
    }
  }
  .block-6 {
    padding-top: 0;
    background: linear-gradient(rgba(255, 255, 255, 1), rgba(244, 245, 246, 1));
    .below-entry {
      position: relative;
      h1 {
        font-size: 20px;
        line-height: 1.5;
        margin-bottom: 20px;
        color: #1B1939;
      }
      h1, .btn-wrap {
        text-align: center;
      }
      .below-bg {
        width: 100%;
        margin-top: 30px;
        img {
          height: 140px;
          margin: 0 auto;
        }
        .line {
          height: 1px;
          background: #1B1A3A;
        }
      }
    }
    .about-row {
      padding: 0 15px;
    }
    .foot-logo {
      width: 160px;
      margin: 30px auto 25px;
    }
    .foot-btns {
      font-size: 14px;
      line-height: 16px;
      a {
        font-size: 14px;
        line-height: 2;
      }
    }
    .foot-navs {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
      a {
        color: rgba(27, 26, 58, 1);
      }
    }
    .mail {
      img {
        display: inline-block;
        width: 14px;
        margin-right: 4px;
        vertical-align: middle;
      }
      a {
        vertical-align: middle;
        color: rgba(27, 26, 58, 0.8);
      }
    }
    .cn-bei {
      padding: 30px 15px 0;
      font-size: 14px;
      color: #1B1939;
      text-align: center;
      a {
        color:#1B1939;
      }
    }
  }

}

@media screen and (max-width: 425px) {
  .home-mobile {
    .block-4 {
     
    }
  }
}
@media screen and (max-width: 320px) {
  .home-mobile {
    .block-6 {
      .foot-navs {
        display: block;
        a {
          margin-right: 4vw;
          &:last-of-type {
            margin-right: 0;
          }
        }
      }
    }
  }
}

@keyframes track11 {
  0% {
    stroke-dasharray: 0, 90px;
  }
  100% {
    stroke-dasharray: 90px, 90px;
  }
}
@keyframes track12 {
  0% {
    stroke-dasharray: 0, 38px;
  }
  100% {
    stroke-dasharray: 38px, 38px;
  }
}
@keyframes track13 {
  0% {
    stroke-dasharray: 0, 106px;
  }
  100% {
    stroke-dasharray: 106px, 106px;
  }
}
@keyframes fadeIn1 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes scaleIn {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes rotateSelf {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(-360deg);
  }
}
@keyframes shake1 {
  0% {
    transform: rotateZ(0);
  }
  20% {
    transform: rotateZ(18deg);
  }
  40% {
    transform: rotateZ(-14deg);
  }
  60% {
    transform: rotateZ(11deg);
  }
  80% {
    transform: rotateZ(-6deg);
  }
  100% {
    transform: rotateZ(0deg);
  }
}
@keyframes lightCross1 {
  0% {
    opacity: 1;
    transform: translate(-25px, -25px);
  }
  49.9% {
    opacity: 0;
    transform: translate(35px, 35px);
  }
  50% {
    opacity: 1;
    transform: translate(-25px, -25px);
  }
  100% {
    opacity: 0;
    transform: translate(35px, 35px);
  }
}
@keyframes breath1 {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1);
  }
  70% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
</style>
