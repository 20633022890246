<template>
  <div class="home">
    <home-pc v-if="screen > 434"></home-pc>
    <home-mobile v-if="screen > 0 && screen <= 434"></home-mobile>
  </div>
</template>
<script>
// import homePc from "@/components/home/home-pc.vue"
// import homeMobile from "@/components/home/home-mobile.vue"
/*import homePc from "./v2/home-pc.vue"
import homeMobile from "./v2/home-mobile.vue"*/
import homePc from "@/page/home/v2/home-pc.vue"
import homeMobile from "@/page/home/v2/home-mobile.vue"
export default {
  metaInfo: {
    title: "北京猎户星空科技有限公司",
    meta: [
      {
        name: "keywords",
        content:
          "猎户开发者平台，OrionBase，机器人开发平台，机器人开发者平台，机器人开放平台，猎户开放平台，机器人开放平台"
      },
      {
        name: "description",
        content: "提供专业的一站式服务，帮助开发者高效完成机器人业务开发"
      }
    ]
  },
  data() {
    return {
      screen: 0
    };
  },
  components: {
    homePc,
    homeMobile
  },
  created() {
    this.screen = window.innerWidth
  }
};
</script>
<style lang="less">
.home.hidden {
  display: none;
}
.home-pc {
  display: block;
}
.home-mobile {
  display: none;
}
@media screen and (max-width: 434px) {
  .home-pc {
    display: none;
  }
  .home-mobile {
    display: block;
  }
}
</style>