<template>
  <div class="cn-top">
    <header class="top-header">
      <div class="ob-logo">
        <a href="/" @click.prevent="handleClick">
          <img src="@/assets/images/logo.png" />
        </a>
      </div>
      <div class="lang-style">
        <slot></slot>
      </div>
      <!-- <div class="pc-top-navs">
        <template v-for="(nav, i) in navs">
          <a 
            :key="i"
            href="javascript:;"
            :class="{'active': isActive(nav)}"
            @click="routerTo(nav, 1)"
          >{{nav.title}}</a>
        </template>
        <div><input type="text"></div>
        <div class="top-btn" @click="toOB(1)">控制台</div>
        <i>123</i>
      </div> -->
      <!-- mobile navs start -->
      <div class="m-top-navs">
        <div
          :class="{'top-menu-icon': true, 'animate-open': open, 'animate-unopen': !open && toggle_count > 0}"
          @click="toggleMobileNavs">
          <div class="line-1"></div>
          <div class="line-2"></div>
          <div class="line-3"></div>
        </div>
        <transition name="slide-fade">
          <ul v-show="open" class="top-menu-container">
            <template v-for="(nav, i) in navs">
              <li
                :key="i">
                <a href="javascript:;" @click="routerTo(nav, 2)">{{nav.title}}</a>
              </li>
            </template>
            <li>
              <a class="console-btn" :href="$envConfig.obUrl" @click.prevent="toOB(2)">控制台</a>
            </li>
          </ul>
        </transition>
      </div>
      <!-- mobile navs start -->
    </header>
  </div>
</template>
<script>

export default {
  props: {
    page: {
      type: String,
      default: ''
    },
    navs: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      open: false,
      toggle_count: 0,
    }
  },
  mounted() {},
  methods: {
    // isActive(nav) {
    //   let name = this.$route.meta.name
    //   let navName = nav.route_name
    //   if (navName == name) return true
    //   return false
    // },
    handleClick() {
      if (this.$route.path == '/') return;
      this.$utils.GATracking('返回首页', '点击', this.page)
      this.$router.push('/')
    },
    routerTo(nav, type) {
      if (type == 2) {
        this.toggleMobileNavs()
      }
      let { path, route_name, title } = nav
      let name = this.$route.meta.name
      if (name == route_name) return
      this.$utils.GATracking(title, '点击', `${type == 1 ? 'PC' : 'Mobile'}-${this.page}`)
      this.$router.push(path)
    },
    toOB(type) {
      this.$utils.GATracking('跳转控制台', '点击', `${type == 1 ? 'PC' : 'Mobile'}-${this.page}`)
      location.href = this.$envConfig.obUrl
    },
    toggleMobileNavs() {
      this.open = !this.open
      this.toggle_count++
    }
  }
}
</script>
<style lang="less">
@blue: #6976FF;
.cn-top {
  background: #fff;
  box-sizing: border-box;
  height: 68px;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
}
.top-header {
  display: flex;
  height: 100%;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  position: relative;
  .lang-style{
      display: none;
  }
  .ob-logo {
    display: inline-block;
    img {
      display: block;
      width: 187px;
    }
  }
//   .pc-top-navs {
//     display: flex;
//     align-items: center;
//     a {
//       position: relative;
//       font-size: 18px;
//       color: #353535;
//       text-decoration: none;
//       cursor: pointer;
//       margin-left: 40px;
//       &:hover {
//         text-decoration: none;
//       }
//       &::after {
//         content: "";
//         position: absolute;
//         display: block;
//         bottom: -21px;
//         left: 0;
//         right: 0;
//         height: 2px;
//         background: #2E2E2E;
//         transform: scaleX(0);
//         opacity: 0;
//         transition: opacity .15s, transform .2s cubic-bezier(.20, .60, .23, .86);
//       }
//       &.active,&:hover {
//         color: #2E2E2E;
//         &::after {
//           opacity: 1;
//           transform: scaleX(1);
//         }
//       }
//     }
//   }
//   .top-btn {
//     display: flex;
//     box-sizing: border-box;
//     height: 38px;
//     padding: 10px 30px;
//     font-size: 18px;
//     align-items: center;
//     color: #1B1A3A;
//     border: 1px solid #1B1A3A;
//     border-radius: 19px;
//     cursor: pointer;
//     margin-left: 48px;
//   }

  .m-top-navs {
    display: none;
    .top-menu-icon {
      width: 25px;
      height: 18px;
      overflow: hidden;
      div {
        position: relative;
        width: 100%;
        height: 2px;
        border-radius: 2px;
        background: @blue;
        margin-bottom: 6px;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
      &.animate-open {
        .line-1, .line-3 {
          width: 50px;
        }
        .line-1 {
          animation: jx1 .3s both;
        }
        .line-2 {
          opacity: 0;
          transition: opacity .2s;
        }
        .line-3 {
          animation: jx3 .3s both;
        }
      }
      &.animate-unopen {
        .line-1, .line-3 {
          width: 50px;
        }
        .line-1 {
          animation: jx1r .3s both;
        }
        .line-2 {
          opacity: 1;
          transition: opacity .2s;
        }
        .line-3 {
          animation: jx3r .3s both;
        }
      }
    }
    .top-menu-container {
      position: absolute;
      right: 26px;
      top: 56px;
      padding: 24px 30px;
      background: #fff;
      box-shadow: 0px 0px 11px 1px rgba(128, 128, 128, 0.28);
      border-radius: 4px;
      li {
        list-style: none;
        height: 30px;
        padding: 10px 0;
        line-height: 30px;
        text-align: center;
        a {
          font-size: 16px;
          text-decoration: none;
          color: #1B1A3A;
        }
        .console-btn {
          display: block;
          background: #6976FF;
          color: #fff;
          height: 30px;
          padding: 0 20px;
          border-radius: 15px;
        }
      }
    }
    .slide-fade-enter-active, .slide-fade-leave-active {
      transition: all .3s ease;
    }
    .slide-fade-enter, .slide-fade-leave-to {
      transform: translateX(10px);
      opacity: 0;
    }
  }
}

@keyframes jx1 {
  from {
    transform: translateX(0) rotateZ(0);
  }
  to {
    transform: translateX(-2px) rotateZ(-45deg);
  }
}
@keyframes jx1r {
  from {
    transform: translateX(-2px) rotateZ(-45deg);
  }
  to {
    transform: translateX(0) rotateZ(0);
  }
}

@keyframes jx3 {
  from {
    transform: translateX(0) rotateZ(0);
  }
  to {
    transform: translateX(-2px) rotateZ(45deg);
  }
}
@keyframes jx3r {
  from {
    transform: translateX(-2px) rotateZ(45deg);
  }
  to {
    transform: translateX(0) rotateZ(0);
  }
}

@media screen and (max-width: 434px) {
  .cn-top {
    height: 56px !important;
    padding: 0 !important;
    .top-header {
      padding: 0 24px !important;
      .lang-style{
        display: block;
      }
      .ob-logo img {
        width: 120px;
      }
      .pc-top-navs {
        display: none;
      }
      .m-top-navs {
        display: block;
      }
    }
  }
}
</style>
