<template>
  <div class="home-pc">
    <top-header
      :class="{'ct-h-t': true, 'scrolled': scrollTop > 0}"
      ref="home_top_bar">
    </top-header>
    <div class="block block-1">
      <div class="st-wh block-animate-unit">
        <div class="one-left">
          <el-button @click='fn' style="display: none">{{ $t('navbar.dashboard') }}</el-button>
          <h1>Robot OS平台</h1>
          <!--<h2>开放性 易用性 扩展性</h2>-->
          <h3>Robot OS平台是开放的机器人业务解决方案平台。<br>
            依托于猎户星空行业内唯一全链条AI技术，平台为机器人业务开发者提供了一套完整的机器人业务逻辑方案，包括开放的软/硬件环境、开源的业务模板等，机器人开发者不仅可以使用机器人的所有能力，从0到1完成机器人业务开发落地也只需要短短的几天时间。</h3>
          <!--<div class="primary-btn xl" @click="toOB">-->
            <!--<span>查看示例</span>-->
          <!--</div>-->
          <!--<div class="primary-btn xl" @click="toOB">-->
            <!--<span>文档中心</span>-->
          <!--</div>-->
        </div>
        <div ref="animation_wrap_1" class="block-1-bg">
          <img src="@/assets/images/block-1-bg.png" alt="">
          <div class="rects-wrap">
            <div class="rect-1-1"></div>
            <div class="rect-1-2"></div>
          </div>
          <svg class="svg-1-1" width="56" height="36" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <polyline
              class="line-track line-track-1"
              points="1,36 1,1 56,1"></polyline>
          </svg>
          <img class="img-1-1" src="@/assets/images/block-1-1.png" alt="">
          <svg class="svg-1-2" width="38" height="2" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <polyline
              class="line-track line-track-2"
              points="0,1 38,1"></polyline>
          </svg>
          <div class="light-img-wrap">
            <img class="img-1-2" src="@/assets/images/block-1-2.png" alt="">
            <div class="img-1-2-light"></div>
          </div>
          <svg class="svg-1-3" width="36" height="71" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <polyline
              class="line-track line-track-3"
              points="1,1 24,1 24,70 36,70"></polyline>
          </svg>
          <img class="img-1-3" src="@/assets/images/block-1-3.png" alt="">
        </div>
      </div>
    </div>
    <div class="block block-2">
      <h1 class="pri-title">技术能力</h1>
      <div class="st-wh">
        <div class="block-animate-unit">
          <div class="advan-item">
            <img src="@/assets/images/advan-write_2x.png" alt="">
            <h5>场景组件</h5>
            <p>60+场景组件支持</p>
            <p>200+api免费开放使用</p>
          </div>
          <div class="advan-item">
            <img src="@/assets/images/advan-data_2x.png" alt="">
            <h5>语音能力</h5>
            <p>自研全链条AI语音技术</p>
            <p>6麦克风阵列，360度远近全场覆盖</p>
            <p>有声内容矩阵丰富</p>
          </div>
          <div class="advan-item">
            <img src="@/assets/images/advan-team_2x.png" alt="">
            <h5>视觉能力</h5>
            <p>微软百万名人识别竞赛冠军</p>
            <p>人脸人体检测，年龄性别分析</p>
            <p>手势物体识别</p>
          </div>
          <div class="advan-item">
            <img src="@/assets/images/advan-data_2x.png" alt="">
            <h5>导航能力</h5>
            <p>多传感器融合，多模态导航</p>
            <p>定位精准，自主避障优化路线</p>
          </div>
        </div>
      </div>
    </div>
    <!--修改后新加的-->
    <div class="block block-7">
      <h1 class="pri-title">高效开发</h1>
      <div class="st-wh">
        <div class="block-animate-unit">
          <div class="advan-item">
            <img src="@/assets/images/muban.png" alt="">
            <h5>模板</h5>
            <p>模板化开发，轻松上手开发机器人业务</p>
          </div>
          <div class="advan-item">
            <img src="@/assets/images/kaiyuan.png" alt="">
            <h5>开源</h5>
            <p>大量实际业务代码开源</p>
          </div>
          <div class="advan-item">
            <img src="@/assets/images/mianfei.png" alt="">
            <h5>免费</h5>
            <p>免费接入、免费培训</p>
          </div>
        </div>
        <div class="block-animate-unit">
          <div class="advan-item">
            <img src="@/assets/images/duoyang.png" alt="">
            <h5>多样</h5>
            <p>支持不同形态的终端设备</p>
          </div>
          <div class="advan-item">
            <img src="@/assets/images/linghuo.png" alt="">
            <h5>灵活</h5>
            <p>强大的运营系统，让机器人更智能</p>
          </div>
          <div class="advan-item">
            <img src="@/assets/images/dichengben.png" alt="">
            <h5>低成本</h5>
            <p>支持可视化开发，开发语言更高效</p>
          </div>
        </div>
      </div>
    </div>
    <div class="block block-3">
        <h1 class="pri-title">运营中心</h1>
        <div class="st-wh">
          <div class="block-animate-unit">
            <div class="advan-item">
              <img src="@/assets/images/block3-fabu.png" alt="">
              <div>
                <h5>语音配置能力</h5>
                <p>业界领先的自然语言处理能力</p>
                <p>专业，灵活的对话管理平台和服务</p>
              </div>
            </div>
            <div class="advan-item">
              <img src="@/assets/images/block3-zhiliang.png" alt="">
              <div>
                <h5>应用监控分析</h5>
                <p>24小时异常监控，报表呈现，所有问题一目了然</p>
                <p>设置预警机制，问题出现即报警，保障业务稳定性</p>
              </div>
            </div>
          </div>
          <div class="block-animate-unit">
            <div class="advan-item">
              <img src="@/assets/images/block3-zhiliang.png" alt="">
              <div>
                <h5>成熟的应用商店</h5>
                <p>丰富的机器人小程序应用，满足多样性需求</p>
                <p>研发生态成熟，方便企业开发者管理迭代版本</p>
              </div>
            </div>
            <div class="advan-item">
              <img src="@/assets/images/block3-fabu.png" alt="">
              <div>
                <h5>多端互联</h5>
                <p>手机app，小程序，机器人，机器人后台，多端互联</p>
                <p>多端查看，多端管理，运营无处不在</p>
              </div>
            </div>
          </div>
        </div>
    </div>
    <div class="block block-4">
      <div class="st-wh">
        <div class="block-animate-unit">
          <h1 class="pri-title">应用场景</h1>
          <div class="case-wrap">
            <superslide
              class="pc-case-slide-wrap"
              :options="caseSlideOptions"
              >
              <div
                ref="pc_case_slide_bar"
                class="pc-case-slide-bar"
                slot="titCell">
                <ul>
                  <li
                    v-for="(item, index) in case_list"
                    :ref="'pc_case_bar_' + index"
                    :key="index">
                    {{item.label}}
                  </li>
                </ul>
              </div>
              <div class="pc-case-slide-body">
                <div
                  class="case-item"
                  v-for="(item, index) in case_list"
                  :key="index">
                  <img :src="item.pic" alt="">
                  <div class="case-info">
                    <div class="intro-label">{{item.label}}</div>
                    <div class="intro" :title="item.intro">{{item.intro}}</div>
                  </div>
                </div>
              </div>
            </superslide>
          </div>
        </div>
      </div>
    </div>
    <div class="block block-5">
      <div class="st-wh block-animate-unit">
        <h1 class="pri-title">合作伙伴</h1>
        <div class="corps">
          <div class="corp-row">
            <div class="corp-item">
              <img src="@/assets/images/badaling.png" class="ht-60" alt="">
            </div>
            <div class="corp-item">
              <img src="@/assets/images/WandaPlaza.png" class="ht-60" alt="">
            </div>
            <div class="corp-item">
              <img src="@/assets/images/suzhouMuseum.png" class="ht-60" alt="">
            </div>
            <div class="corp-item">
              <img src="@/assets/images/InfoSoft.png" class="ht-60" alt="">
            </div>
          </div>
          <div class="corp-row">
            <div class="corp-item">
              <img src="@/assets/images/jilinUniversity.png" class="ht-69" alt="">
            </div>
            <div class="corp-item">
              <img src="@/assets/images/XianJiaotongUniversity.png" class="ht-69" alt="">
            </div>
            <div class="corp-item">
              <img src="@/assets/images/ShougangFund.png" class="ht-69" alt="">
            </div>
            <div class="corp-item">
              <img src="@/assets/images/ETOURY.png" class="ht-69" alt="">
            </div>
          </div>
          <div class="corp-row">
            <div class="corp-item">
              <img src="@/assets/images/imee.png" class="ht-69" alt="">
            </div>
            <div class="corp-item">
              <img src="@/assets/images/NARDA.png" class="ht-69" alt="">
            </div>
            <div class="corp-item">
              <img src="@/assets/images/Aegis.png" class="ht-69" alt="">
            </div>
            <div class="corp-item">
              <img src="@/assets/images/ARMuseum.png" class="ht-69" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--<div class="block block-6">
      <div class="st-wh">
        <div class="foot-qk">
          <div class="qk-bg">
            <img src="@/assets/images/home-foot-bg-1_2x.png" alt="">
            <div class="line"></div>
          </div>
          <div class="qk-left">
            <h1>OrionBase开发者平台</h1>
            &lt;!&ndash;<div class="primary-btn" @click="toOB">
              <span>立即使用</span>
            </div>&ndash;&gt;
          </div>
        </div>
      </div>
    </div>-->
    <div class="block block-6">
      <div class="st-wh">
        <div class="foot-qk">
          <div class="qk-bg">
            <img src="@/assets/images/home-foot-bg-1_2x.png" alt="">
            <div class="line"></div>
          </div>
          <div class="qk-left">
            <h1>OrionBase开发者平台</h1>
            <div class="primary-btn" @click="toOB">
              <span>立即使用</span>
            </div>
          </div>
        </div>
        <div class="about-row">
          <div class="foot-logo"></div>
          <div class="foot-btns">
            <div class="foot-navs">
              <a href="/doc?m=21&h=c086fc5" @click.prevent="toDoc('/doc?m=21&h=c086fc5')">文档中心</a>
              <a href="/doc?m=21&h=c086fc5" @click.prevent="toDoc('/doc?m=21&h=c086fc5')">关于我们</a>
              <a href="mailto:OrionBase@ainirobot.com"
                @click="$utils.GATracking('点击意见反馈','点击','首页')">意见反馈</a>
              <a href="/doc?m=24&h=14f5e96" @click.prevent="toDoc('/doc?m=24&h=14f5e96')">用户协议</a>
              <a href="/doc?m=24&h=8cb7ad4" @click.prevent="toDoc('/doc?m=24&h=8cb7ad4')">隐私政策</a>
            </div>
            <div class="mail">
              <svg class="email-svg" width="15px" height="13px" version="1.1" xmlns="http://www.w3.org/2000/svg"
                style="background: rgba(27, 26, 58, 0.8); border-radius: 2px; vertical-align: middle; position: relative; top: -1px; margin-right: 5px;"
              ><path fill="none" stroke="rgba(255, 255, 255, 1)" stroke-width="1.4" d="M0 3.5 L7.5 7.2 L15 3.5"></path></svg>
              <a href="mailto:OrionBase@ainirobot.com"
                @click="$utils.GATracking('点击邮箱链接','点击','首页')">邮箱：OrionBase@ainirobot.com</a>
            </div>
          </div>
        </div>
        <div class="cn-bei">
          <a
            target='_blank'
            href='https://beian.miit.gov.cn/'
            @click="$utils.GATracking('备案号','点击','首页底导-ICP备案号')"
          > 猎户星空©2019  • 京公网安备 11010502039185号，京ICP备17016764号-2 </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import topHeader from '@/components/common/top-header'
import i18n from '@/lang'
const CASE_LIST = [
  {
      label: '餐厅',
      intro: '为顾客推荐门店特色餐品，促销信息。对顾客主动招揽，为门店持续引流，让门店脱颖而出。通过多层超大托盘，实现单次多层餐品递送，提高配送效率，降低门店成本。通过机器人与顾客的趣味语音交流推荐会员，引导顾客加入会员及充值，提升会员留存率，提升门店营业额',
      pic: require("@/assets/images/case-restaurant.png")
  },
  {
    label: '医疗',
    intro: '为患者提供无接触红外测温、导诊、预诊、位置指引、医疗咨询等服务，协助医院进行患者分流，提升安全性、降低人力成本，同时能有效辅助医生诊断决策，提升诊疗能力',
    pic: require("@/assets/images/case-medical.png")
  },
  {
    label: '新零售',
    intro: '为顾客提供商品导购、查询、商品推荐、商品介绍、位置指引等服务，为商家提供店铺招揽、店铺引流、营销互动等服务，沉淀顾客消费大数据，挖掘潜在消费能力，促进营销智慧化，打造零售新业态',
    pic: require("@/assets/images/case-market.png")
  },
  {
    label: '政务',
    intro: '为群众提供便捷取号、政务咨询、政务办理等服务，有效帮助政务大厅分流，为工作人员提供桌面智能助理服务，根据用户问题快速给出答案或推荐话术，辅助工作效率提升',
    pic: require("@/assets/images/case-gover_affairs.png")
  },
  {
    label: '企业服务',
    intro: '为企业提供前台广告播放、主动问候、来访人员登记、无关人员安检排查、无接触红外测温、企业引领参观等服务，保证企业接待水平的智能化、高标准、统一性，有效降低人力成本，同时便于对来访人员进行大数据分析',
    pic: require("@/assets/images/case-corp_service.png")
  },
  {
    label: '展馆',
    intro: '为博物馆、展览馆等提供高水平、标准统一的讲解服务，快速适应不同讲解场景、复杂度的内容，降低人工讲解的成本、减少讲解失误率，同时结合实际场景完成智能引导，快捷解决游客需求，有效提升游客的参观满意度',
    pic: require("@/assets/images/case-exhibition.png")
  },
  {
    label: '教育',
    intro: '为校园提供迎宾、访客接待、校园导览、咨询讲解等智能服务，针对图书馆、实验室等场景，提供智慧索引、知识科普、咨询问答等一站式服务，有效缓解教职工工作压力、提升校园智能化水平，同时方便学校实现统一的信息化管理',
    pic: require("@/assets/images/case-education.png")
  },
  {
    label: '酒店',
    intro: '为酒店提供24H的前台值班、入住办理、咨询问答等服务，可根据不同入住时段设置接待欢迎语，主动打招呼互动提供引领等服务，还可以设置酒店常用地点库，介绍酒店宴会场地、包餐菜单等内容，获取周边美食、景点、交通情况等，提供最优质的入住体验',
    pic: require("@/assets/images/case-hotel.png")
  },
  {
    label: '银行',
    intro: '为客户提供主动招揽、基础操作问题答疑、基础业务办理等服务，降低人工成本，同时针对VIP用户精准营销，提供定制化、高标准服务，另外可针对等候区域用户可提供游戏互动式活动推广、有效增加业务转化量',
    pic: require("@/assets/images/case-bank.png")
  }
]
export default {
  components: {
    topHeader
  },
  data() {
    let that = this
    return {
      scrollTop: 0,
      case_list: Object.freeze(CASE_LIST),
      caseSlideOptions: {
        mainCell: '.pc-case-slide-body',
        titCell: '.pc-case-slide-bar li',
        effect: 'leftLoop',
        trigger: 'click',
        interTime: 5000,
        autoPlay: true,
        easing: 'easeOutQuint'
      },
    }
  },
    created(){
      this.fn()
    },
  mounted() {
    window.addEventListener("scroll", this.handleScroll)
    this.handleScroll()
  },
  methods: {
    fn(){
      let type;
      if(this.$store.getters['app/language']=='en'){
        type = 'zh'
      }else{
        type = 'zh'
      }
      this.$i18n.locale = type
      this.$store.dispatch('app/setLanguage', type)

    },
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      this.scrollTop = scrollTop;
      let wh = window.innerHeight
      let wrap1 = this.$refs.animation_wrap_1
      let wrap2 = this.$refs.animation_wrap_2
      let wrap3 = this.$refs.animation_wrap_3
      let wrap4 = this.$refs.animation_wrap_4
      if (wrap1 && wrap1.className.indexOf('animate') == -1) {
        if (scrollTop <= 251) {
          wrap1.classList.add('animate')
        }
      }
      if (wrap2 && wrap2.className.indexOf('animate') == -1) {
        let bound2 = wrap2.getBoundingClientRect()
        if (bound2.top > 30 && bound2.bottom < wh + 20) {
          wrap2.classList.add('animate')
        }
      }
      if (wrap3 && wrap3.className.indexOf('animate') == -1) {
        let bound3 = wrap3.getBoundingClientRect()
        if (bound3.top > -30 && bound3.bottom < wh + 20) {
          wrap3.classList.add('animate')
        }
      }
      if (wrap4 && wrap4.className.indexOf('animate') == -1) {
        let bound4 = wrap4.getBoundingClientRect()
        if (bound4.top > -70 && bound4.bottom < wh + 20) {
          wrap4.classList.add('animate')
        }
      }
    },
    toOB() {
      location.href = this.$envConfig.obUrl
    },
    toDoc(path) {
      this.$router.push(path)
    }
  }
}
</script>
<style lang="less">
@import url("~style/style/btns.less");
@blue: #6975FF;
@bezier: cubic-bezier(.70, .65, .23, .1);
@lineColor: #1B1A3A;
@conWidth: 1240px;
.home-pc {
  .ct-h-t {
    .searchform {
      background: #fff;
    }
    &:hover {
      .searchform {
        background: #f4f5f6;
      }
      background: #f4f5f6;
      .el-dropdown-link {
        background: transparent!important;
      }
    }
    &.scrolled {
      background:  #f4f5f6;
      /*background: rgba(252, 252, 253, 1);*/
      box-shadow: 0px 1px 9px 0px rgba(41, 43, 57, 0.2);
      .searchform {
        background: #f4f5f6;
      }
      .el-dropdown-link {
        background: transparent!important;
      }
    }
    .top-header {
      width: @conWidth;
      margin: 0 auto;
    }
  }
  .block {
    padding: 75px 0;
    .pri-title {
      font-size: 42px;
      line-height: 42px;
      color: #1B1939;
      text-align: center;
    }
    img {
      display: block;
      max-width: 100%;
      max-height: 100%;
    }
    a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .st-wh {
    width: @conWidth;
    height: 100%;;
    margin: 0 auto;
    box-sizing: border-box;
  }
  // .block-animate-unit {
  //   position: relative;
  // }
  // .block.animate {
  //   .block-animate-unit {
  //     animation: pageTranslate 1s 0s both;
  //   }
  // }
  .block-1 {
    height: 600px;
    padding-top:50px;
    padding-bottom: 0;
    .st-wh {
      position: relative;
      height: 100%;
    }
    h1 {
      color: #1B1939;
      font-size: 40px;
      line-height: 80px;
      padding: 180px 0 20px;
      text-indent: -2px;
    }
    h2 {
      font-weight: normal;
      color: #393843;
      font-size: 24px;
      line-height: 24px;
      margin: 0 0 18px;
    }
    h3 {
      width: 400px;
      color: #393843;
      font-size: 16px;
      line-height: 26px;
      margin: 0 0 18px;
      font-weight: 400;
    }
    .block-1-bg {
      position: absolute;
      right: 0;
      bottom: 10px;
      width: 882px;
      height: 565px;
      .rects-wrap, svg, .img-1-1, .img-1-3, .light-img-wrap, .img-1-2-light {
        position: absolute;
      }
      .img-1-1, .img-1-2, .img-1-3 {
        opacity: 0;
      }
      .rects-wrap {
        left: 321px;
        top: 294px;
        width: 0;
        height: 10px;
        overflow: hidden;
        div {
          position: absolute;
          box-sizing: border-box;
          top: 0;
          height: 10px;
          border: 2px solid @lineColor;
          border-radius: 2px;
          margin: 0;
        }
        .rect-1-1 {
          left: 0;
          width: 24px;
        }
        .rect-1-2 {
          left: 28px;
          width: 58px;
        }
      }
      .line-track {
        fill: none;
        stroke: @lineColor;
        stroke-width: 2px;
        stroke-dasharray: 0, 100%;
      }
      .svg-1-1 {
        left: 380px;
        top: 189px;
      }
      .img-1-1 {
        left: 424px;
        top: 164px;
        width: 57px;
      }
      .svg-1-2 {
        left: 587px;
        top: 168px;
      }
      .light-img-wrap {
        width: 54px;
        height: 59px;
        left: 624px;
        top: 122px;
        overflow: hidden;
      }
      .img-1-2 {
        width: 54px;
      }
      .img-1-2-light {
        z-index: 10;
        left: 0;
        top: 0;
        width: 60px;
        height: 60px;
        opacity: 0;
        background: linear-gradient(135deg, rgba(255, 255, 255, 0) 40%, rgba(255, 255, 255, .85) 50%,rgba(255, 255, 255, 0) 60%);
      }
      .svg-1-3 {
        left: 670px;
        top: 168px;
      }
      .img-1-3 {
        left: 705px;
        top: 197px;
        width: 50px;
      }
    }
    .block-1-bg.animate {
      .rects-wrap {
        animation: widthGrow1 0.5s @bezier 1s both;
      }
      .line-track-1 {
        animation: track11 0.5s @bezier 1.5s both;
      }
      .img-1-1 {
        animation: fadeIn1 1s linear 2s both, shake1 .6s ease-in-out 3s 3 both;
      }
      .line-track-2 {
        animation: track12 0.3s @bezier 1.5s both;
      }
      .img-1-2 {
        animation: fadeIn1 1s linear 1.8s both;
      }
      .img-1-2-light {
        animation: lightCross1 1.2s ease-in-out 2.8s 1;
      }
      .line-track-3 {
        animation: track13 0.5s @bezier 3.5s both;
      }
      .img-1-3 {
        animation: fadeIn1 1s linear 4s both, breath1 1s ease-in-out 4.5s 2 both;
      }
    }
  }
  .block-2 {
    padding: 75px 0 0 0;
    background: rgba(236, 237, 254, 1);
    .st-wh {
      background: rgba(236, 237, 254, 1);
      padding: 75px 30px;
    }
    .block-animate-unit {
      display: flex;
      justify-content: space-between;
    }
    .advan-item {
      width: 25%;
      padding: 0 10px;
      text-align: center;
      h5, p {

      }
      h5 {
        font-size: 22px;
        line-height: 22px;
        margin: 40px 0 20px;
      }
      p {
        font-size: 16px;
        line-height: 26px;
      }
      img {
        display: inline-block;
        width: 120px;
        height: 120px;
        /*margin-bottom: 40px;*/
      }
    }
  }
  .block-7 {
    padding: 75px 0 0 0;
    .st-wh {
      padding: 75px 30px;
    }
    .block-animate-unit {
      display: flex;
      justify-content: space-between;
      text-align: center;
      margin-bottom: 30px;
    }
    .advan-item {
      width: 25%;
      padding: 0 10px;
      h5 {
        font-size: 22px;
        line-height: 22px;
        margin: 20px 0 20px;
      }
      p {
        font-size: 16px;
        line-height: 26px;
      }
      img {
        display: inline-block;
        width: 120px;
        height: 120px;
      }
    }
  }
  .block-3 {
    background: #fff;
    padding: 0;
    h1 {
    }
    .st-wh {
      padding: 75px 30px;
    }
    .block-animate-unit {
      display: flex;
      padding: 0 0 80px 40px;
    }
    .advan-item {
      width: 50%;
      /*padding: 0 10px;*/
      display: flex;
      h5 {
        font-size: 22px;
        line-height: 22px;
        margin-bottom: 10px;
      }
      p {
        font-size: 16px;
        line-height: 26px;
      }
      img {
        display: block;
        width: 120px;
        height: 120px;
        margin: -20px 30px 20px 0;
      }
    }
  }
  .block-4 {
    padding: 0;
    background: #F3F5F5;
    .st-wh {
      padding: 75px 30px;
      background: #F3F5F5;
    }
    .case-wrap {
      position: relative;
      padding: 22px;
      margin-top: 120px ;
      border: 1px solid rgba(213, 212, 212, 1);
      background: #fff;
      .pc-case-slide-bar {
        position: absolute;
        top: -36px;
        left: 0;
        right: 0;
        height: 36px;
        overflow: auto;
        text-align: center;
        &::-webkit-scrollbar {
          width: 2px;
          height: 2px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #EAEAEA;
          border-radius: 2px;
        }
        ul {
          height: 100%;
          white-space: nowrap;
          &::after {
            content: "";
            display: block;
            height: 0;
            overflow: hidden;
            clear: both;
          }
        }
        li {
          display: inline-block;
          list-style: none;
          height: 22px;
          line-height: 22px;
          font-size: 20px;
          position: relative;
          padding: 0 10px;
          margin: 0 16px;
          cursor: pointer;
          &:first-of-type {
            margin-left: 0;
          }
          &:last-of-type {
            margin-right: 0;
          }
          &::after {
            content: "";
            position: absolute;
            bottom: -14px;
            z-index: 10;
            left: 10px;
            right: 10px;
            height: 2px;
            background: @blue;
            transform: scaleX(0);
            opacity: 0;
            transition: opacity .15s, transform .2s cubic-bezier(.20, .60, .23, .86);
          }
          &.on {
            color: @blue;
            &::after {
              opacity: 1;
              transform: scaleX(1);
            }
          }
        }
      }
      .case-item {
        display: flex;
      }
      img {
        width: 263px;
        height: 185px;
        margin-right: 30px;
      }
      .case-info {
        margin: 30px 0;
        padding: 0 30px;
        border-left: 1px solid rgba(213, 212, 212, 1);
      }
      .intro-label {
        font-size: 18px;
        line-height: 18px;
        margin: 9px 0 15px;
        color: #393842;
      }
      .intro {
        font-size: 18px;
        line-height: 26px;
        color: #898989;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        overflow: hidden;
        -webkit-box-orient: vertical;
      }
    }
  }
  .block-5 {
    padding-bottom: 0;
    .corps {
      margin-top: 40px;
    }
    .corp-row {
      .corp-item:last-of-type {
        margin-right: 0;
      }
    }
    .corp-item {
      display: inline-flex;
      width: 248px;
      height: 70px;
      padding: 20px;
      margin-right: 28px;
      align-items: center;
      /*img {*/
        /*!*filter: grayscale(1);*!*/
      /*}*/
      /*&:hover img {*/
        /*filter: unset;*/
      /*}*/
      /*.ht-69 {*/
        /*height: 69px;*/
      /*}*/
      /*.ht-56 {*/
        /*height: 56px;*/
      /*}*/
      /*.ht-42 {*/
        /*height: 42px;*/
      /*}*/
    }
  }
  .block-6 {
    padding-top: 0;
    padding-bottom: 0;
    background: linear-gradient(rgba(255, 255, 255, 1), rgba(244, 245, 246, 1));
    .st-wh {
      background: linear-gradient(rgba(255, 255, 255, 1), rgba(244, 245, 246, 1));
      margin-top: 50px;
     /* border-top: 1px solid #ddd;*/
    }
    .foot-qk {
      position: relative;
      height: 286px;
    }
    .qk-left {
      position: absolute;
      left: 60px;
      top: 116px;
      h1 {
        font-size: 38px;
        line-height: 38px;
        margin-bottom: 30px;
        color: #1B1939;
      }
    }
    .qk-bg {
      position: relative;
      height: 100%;
      img {
        position: absolute;
        bottom: 1px;
        right: 60px;
      }
      .line {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 2px;
        background: #1B1A3A;
      }
    }
    .about-row {
      padding: 60px 60px 0;
      display: flex;
      justify-content: space-between;
      .foot-logo {
        width: 196px;
        height: 38px;
        background: url("~style/images/ob-logo-foot.png") no-repeat center;
        background-size: 100% 100%;
      }
      .foot-btns {
        font-size: 16px;
        line-height: 16px;
      }
      a {
        font-size: 16px;
      }
      .foot-navs {
        margin-bottom: 15px;
        a {
          color: rgba(27, 26, 58, 1);
          margin-right: 40px;
          &:last-of-type {
            margin-right: 0;
          }
        }
      }
      .mail a {
        color: rgba(27, 26, 58, 0.8);
      }
    }
    .cn-bei {
      padding-top: 60px;
      padding-bottom: 40px;
      font-size: 16px;
      color: #1B1939;
      text-align: center;
      a {
        color:#1B1939;
      }
    }
  }
}
// @keyframes pageTranslate {
//   0% {
//     opacity: 0.7;
//     transform: translateY(20px);
//   }
//   100% {
//     opacity: 1;
//     transform: translateY(0);
//   }
// }
@keyframes widthGrow1 {
  0% {
    width: 0;
  }
  100% {
    width: 88px;
  }
}
@keyframes track11 {
  0% {
    stroke-dasharray: 0, 90px;
  }
  100% {
    stroke-dasharray: 90px, 0;
  }
}
@keyframes track12 {
  0% {
    stroke-dasharray: 0, 38px;
  }
  100% {
    stroke-dasharray: 38px, 0;
  }
}
@keyframes track13 {
  0% {
    stroke-dasharray: 0, 106px;
  }
  100% {
    stroke-dasharray: 106px, 0;
  }
}
@keyframes track251 {
  0% {
    stroke-dasharray: 0, 251px;
  }
  100% {
    stroke-dasharray: 251px, 0;
  }
}
@keyframes track263 {
  0% {
    stroke-dasharray: 0, 263px;
  }
  100% {
    stroke-dasharray: 263px, 0;
  }
}
@keyframes trackOffset {
  0% {
    stroke-dashoffset: 0%;
  }
  100% {
    stroke-dasharray: 100%;
  }
}
@keyframes fadeIn1 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes leftIn {
  0% {
    opacity: 0;
    transform: translateX(-10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes rightIn {
  0% {
    opacity: 0;
    transform: translateX(10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes shake1 {
  0% {
    transform: rotateZ(0);
  }
  20% {
    transform: rotateZ(18deg);
  }
  40% {
    transform: rotateZ(-14deg);
  }
  60% {
    transform: rotateZ(11deg);
  }
  80% {
    transform: rotateZ(-6deg);
  }
  100% {
    transform: rotateZ(0deg);
  }
}
@keyframes lightCross1 {
  0% {
    opacity: 1;
    transform: translate(-25px, -25px);
  }
  100% {
    opacity: 0;
    transform: translate(35px, 35px);
  }
}
@keyframes breath1 {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1);
  }
  70% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes breath2 {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
  70% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes followCurveTrack {
  0% {
    offset-distance: 100%;
  }
  100% {
    offset-distance: 0%;
  }
}
@keyframes scaleIn {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes scaleIn2 {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  30% {
    opacity: 0.5;
    transform: scale(1.3);
  }
  80% {
    opacity: 0.9;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes rotateSelf {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(-360deg);
  }
}
@media screen and (max-width: 1240px) {
  .home-pc {
    width: 1240px;
    .cn-top {
      width: 1240px;
      position: absolute;
    }
  }
}
</style>
